import React, { useEffect } from "react";
import { useLocation } from "@reach/router";
import Alert from "react-bootstrap/Alert";

import translations from "../translations";

export default function Error({ navigate }) {
  const location = useLocation();

  useEffect(() => {
    const path = (/#!(\/.*)$/.exec(location.hash) || [])[1];
    if (path) {
      navigate(path, { replace: true });
    }
  }, [location, navigate]);

  return (
    <Alert variant="danger">
      <Alert.Heading>{translations.headingError.de}</Alert.Heading>
      <p dangerouslySetInnerHTML={{ __html: translations.textError.de }} />
      <hr/>
      <Alert.Heading>{translations.headingError.en}</Alert.Heading>
      <p dangerouslySetInnerHTML={{ __html: translations.textError.en }} />
    </Alert>
  );
}
