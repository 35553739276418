import React from "react";
import StarRatingComponent from 'react-star-rating-component';
import translations from '../translations';

export default function Intro({language}) {
  return (
    <div className="mb-4">
      <h3>
        {translations.introduction.title[language]}
      </h3>
      <p>
        {translations.introduction.text[language]}
      </p>
      <div className="d-flex justify-content-end">
          <h4 className="mr-4 align-self-center font-weight-bold">{translations.introduction.fiveStars[language]} =</h4>
        <div>
          <StarRatingComponent
            className="mx-auto ml-md-auto"
            name="Good"
            starColor="#90000a"
            emptyStarColor="#7f7f7f"
            value="5"
          />
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <h4 className="mr-4 align-self-center font-weight-bold">{translations.introduction.oneStar[language]} =</h4>
        <div>
          <StarRatingComponent
            className="mx-auto ml-md-auto"
            name="Good"
            starColor="#90000a"
            emptyStarColor="#7f7f7f"
            value="1"
          />
        </div>
      </div>
    </div>

  );
}