import React from "react";

export default function QuestionGroup({ label, children }) {
  return (
    <fieldset className="mb-5">
      <h2>{label}</h2>
      {children}
    </fieldset>
  );
}
