import React from "react";
import Form from "react-bootstrap/Form";
import { useField } from "formik";

export default function FreetextQuestion({ text, id }) {
  const [field, meta] = useField(id);
  return (
    <Form.Group controlId={id}>
      <Form.Label>{text}</Form.Label>
      <Form.Control
        as="textarea"
        rows="4"
        name={id}
        onChange={field.onChange}
        value={field.value}
        isInvalid={meta.touched && !!meta.error}
      />
    </Form.Group>
  );
}
