import axios from "axios";

import { sleep } from "./util";

// TODO: set dynamically from environment
const _API_ENDPOINT_ =
  "https://d6ej62v3ag.execute-api.eu-central-1.amazonaws.com/api";

export function loadCampaign(campaignId, nonce) {
  return axios
    .get(`${_API_ENDPOINT_}/campaigns/${campaignId}/${nonce}`)
    .then((response) => {
      return response.data;
    })
    .then(sleep(1000))
    .catch((error) => {
      console.log(error);
      throw error;
    });
}

export function submitFeedback(campaignId, nonce, values) {
  // return new Promise((resolve, reject) => {
  //   alert(JSON.stringify(values, null, 2));
  //   resolve();
  // });
  return axios
    .put(`${_API_ENDPOINT_}/feedback/${campaignId}/${nonce}`, values)
    .catch((error) => {
      console.log(error);
      throw error;
    });
}
