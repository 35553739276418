import React from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";

import logo from "../img/logo.jpg";

export default function Header() {
  return (
    <Navbar fixed="top" variant="light" bg="white" className="shadow">
      <Container>
        <Navbar.Brand>
          <img src={logo} alt="avrami business communication Logo" />
        </Navbar.Brand>
        <Navbar.Text className="mr-auto">Feedback</Navbar.Text>
      </Container>
    </Navbar>
  );
}
