import React from "react";
import Container from "react-bootstrap/Container";

export default function Footer() {
  return (
    <footer className="bg-dark text-light">
      <Container className="text-center py-5">
        &copy; 2022{" "}
        <a href="https://avrami.com" className="text-reset">
          avrami.com
        </a>
      </Container>
    </footer>
  );
}
