import React from "react";
import Form from "react-bootstrap/Form";
import { useField } from "formik";

import translations from "../translations";

export default function PrivacyNotice({ id, language }) {
  const [field, meta] = useField(id);

  return (
    <fieldset className="mb-5">
      <h2>{translations.headingLabelPrivacyNotice[language]}</h2>
      <p
        dangerouslySetInnerHTML={{
          __html: translations.textPrivacyNotice[language],
        }}
      />
      <Form.Group controlId={id}>
        <Form.Check
          name={id}
          label={translations.checkboxLabelPrivacyNotice[language]}
          onChange={field.onChange}
          value={field.value}
          isInvalid={meta.touched && !!meta.error}
          feedback={translations.errorMsgPrivacyNotice[language]}
        />
      </Form.Group>
    </fieldset>
  );
}
