import React from "react";
import { Router } from "@reach/router";
import Container from "react-bootstrap/Container";

import CampaignForm from "./components/CampaignForm";
import Error from "./components/Error";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Success from "./components/Success";

export default function App() {
  return (
    <>
      <Header />
      <Container
        className="py-5"
        style={{
          minHeight: "calc(100vh - 90px - 120px",
        }}
      >
        <Router>
          <CampaignForm path="/:campaignId/:nonce" />
          <Success path="/success" />
          <Error default />
        </Router>
      </Container>
      <Footer />
    </>
  );
}
