const TRANSLATIONS = {
  introduction: {
    title: {
      de: "Herzlich Willkommen!",
      en: "Welcome!",
    },
    text: {
      de: "Wir freuen uns, dass Sie unser Training bewerten.",
      en: "We are happy that you rate our training."
    },
    fiveStars: {
      de: "Exzellent",
      en: "Excellent"
    },
    oneStar: {
      de: "Schwach",
      en: "Poor"
    }
  },
  errorMsgRatingQuestion: {
    de: "Bitte nehmen Sie eine Bewertung zu dieser Frage vor",
    en: "Please rate this question",
  },
  headingLabelPrivacyNotice: {
    de: "Datenschutzerklärung",
    en: "Privacy statement",
  },
  textPrivacyNotice: {
    de: `
        avrami verwendet die Daten des Feedbackbogens ausschließlich zu
        Evaluationszwecken und leitet sie nicht an Dritte weiter. Da Ihr
        Unternehmen ein berechtigtes Interesse an der Qualität der bei uns
        beauftragten Trainings hat, geben wir Ihre Bewertung auf Anfrage
        <strong>anonymisiert</strong> an die entsprechende Abteilung weiter.
        Eine personalisierte Weitergabe Ihres Feedbacks an Ihr Unternehmen
        erfolgt nur mit Ihrer vorherigen Zustimmung.
    `,
    en: `
        avrami assures that any data obtained will be used for the purpose of
        evaluation only and will not be passed on to third parties. Since your
        company has a justified interest in the quality of trainings ordered
        with us, we will, upon request, forward your evaluation in an
        <strong>anonymous</strong> form to the respective department.
        Personalised information given in our feedbacks will be made available
        to your company with your prior consent only.
    `,
  },
  checkboxLabelPrivacyNotice: {
    de: "Ich stimme zu.",
    en: "I agree.",
  },
  errorMsgPrivacyNotice: {
    de: "Bitte stimmen Sie der Datenschutzerklärung zu",
    en: "Please accept the privacy statement",
  },
  submitLabel: {
    de: "Feedback einreichen",
    en: "Submit feedback",
  },
  headingSuccess: {
    de: "Wir haben Ihr Feedback erhalten.",
    en: "We have received your feedback.",
  },
  textSuccess: {
    de: `Vielen Dank für Ihre Unterstützung.`,
    en: `Many thanks for your support.`,
  },
  headingError: {
    de: "Es ist ein Fehler aufgetreten.",
    en: "An unexpected error occurred.",
  },
  textError: {
    de: `
        Wir konnten Ihre Anfrage leider nicht verarbeiten. Dies kann
        verschiedene Gründe haben:
        <br />
        <ul>
          <li>Der von Ihnen verwendete Link ist ungültig.</li>
          <li>Es gibt ein technisches Problem auf unseren Servern.</li>
        </ul>
    `,
    en: `
        We could not process your request. This problem can have
        various reasons:
        <br />
        <ul>
          <li>The link you used is not valid.</li>
          <li>There are technical issues on our servers.</li>
        </ul>
    `,
  },
};

export default TRANSLATIONS;
