import React from "react";
import Alert from "react-bootstrap/Alert";

import translations from "../translations";

export default function Success() {
  return (
    <Alert variant="success">
      <Alert.Heading>{translations.headingSuccess.de}</Alert.Heading>
      <p dangerouslySetInnerHTML={{ __html: translations.textSuccess.de }} />
      <hr/>
      <Alert.Heading>{translations.headingSuccess.en}</Alert.Heading>
      <p dangerouslySetInnerHTML={{ __html: translations.textSuccess.en }} />
    </Alert>
  );
}
