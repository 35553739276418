import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import classnames from "classnames";
import { useField } from "formik";
import StarRatingComponent from "react-star-rating-component";

import translations from "../translations";

export default function RatingQuestion({ text, id }) {
  const [field, meta, { setValue }] = useField(id);
  const isInvalid = meta.touched && meta.error;

  return (
    <Form.Group controlId={id}>
      <Row
        className={classnames(
          "bg-white",
          "border",
          isInvalid ? "border-danger" : "border-white"
        )}
      >
        <Col md={7}>
          <Form.Label
            className={classnames(
              "text-center",
              "text-md-left",
              isInvalid ? "pt-2" : "pt-4"
            )}
          >
            {isInvalid && (
              <div className="invalid-feedback d-block">
                {translations.errorMsgRatingQuestion.de} / {translations.errorMsgRatingQuestion.en}
              </div>
            )}
            {text}
          </Form.Label>
        </Col>
        <Col md={5} className="d-flex">
          <StarRatingComponent
            className="mx-auto ml-md-auto"
            name={id}
            starColor="#90000a"
            emptyStarColor="#7f7f7f"
            value={parseInt(field.value, 10)}
            onStarClick={(value) => setValue(value.toString())}
            // onStarHover={Function(nextValue, prevValue, name)} /* on icon hover handler */
            // onStarHoverOut={Function(nextValue, prevValue, name)} /* on icon hover out handler */
          />
        </Col>
      </Row>
    </Form.Group>
  );
}
