import React, { useCallback, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Formik } from "formik";
import {
  cond,
  constant,
  flatten,
  flow,
  fromPairs,
  map,
  mapValues,
  matches,
  partialRight,
  pickBy,
  stubFalse,
  stubTrue,
} from "lodash-es";

import translations from "../translations";
import { loadCampaign, submitFeedback } from "../feedbackService";
import QuestionGroup from "./QuestionGroup";
import RatingQuestion from "./RatingQuestion";
import FreetextQuestion from "./FreetextQuestion";
import PrivacyNotice from "./PrivacyNotice";
import LoadingSpinner from "./LoadingSpinner";
import Intro from "./Intro";

const PRIVACY_NOTICE_FIELD_ID = "privacy-notice-field-id";

export default function CampaignForm({ campaignId, nonce, navigate }) {
  const [loading, setLoading] = useState(true);
  const [campaign, setCampaign] = useState();
  const [campaignLanguage, setCampaignLanguage] = useState("de");

  const isPrivacyNoticeField = (value, key) => key === PRIVACY_NOTICE_FIELD_ID;

  const errorHandler = useCallback(
    (error) => {
      if (error.response && error.response.status === 409) {
        navigate("/success");
      } else {
        navigate("/");
      }
    },
    [navigate]
  );

  useEffect(() => {
    loadCampaign(campaignId, nonce)
      .then((campaign) => {
        setCampaign(campaign);
        setCampaignLanguage(campaign["campaign_language"]);
        setLoading(false);
      })
      .catch(errorHandler);
  }, [campaignId, nonce, errorHandler]);

  if (loading) {
    return <LoadingSpinner />;
  }

  const questions = flow(
    (_) => map(_, (_) => _.questions),
    (_) => flatten(_),
    (_) => map(_, (_) => [_.key, _]),
    (_) => fromPairs(_)
  )(campaign.question_groups);

  const initialValues = mapValues(questions, ({ type }, _) =>
    type === "rating" ? "0" : ""
  );
  initialValues[PRIVACY_NOTICE_FIELD_ID] = false;

  const validate = flow(
    (_) =>
      pickBy(
        _,
        cond([
          [isPrivacyNoticeField, (value) => !value],
          [
            stubTrue,
            (value, key) => questions[key].type === "rating" && value === "0",
          ],
        ])
      ),
    (_) => mapValues(_, constant(true))
  );

  const handleSubmit = flow(
    (_) =>
      pickBy(
        _,
        cond([
          [isPrivacyNoticeField, stubFalse],
          [stubTrue, (_) => !!_],
        ])
      ),
    (_) =>
      submitFeedback(campaignId, nonce, _)
        .then(() => navigate("/success"))
        .catch(errorHandler)
  );

  const submitButton = (formik) => (
    <div className="d-flex flex-row justify-content-center justify-content-md-end">
      <Button
        variant="primary"
        size="lg"
        type="submit"
        disabled={formik.submitCount && !formik.isValid}
      >
        {translations.submitLabel[campaignLanguage]}
      </Button>
    </div>
  );

  const buildFormFields = partialRight(
    map,
    cond([
      [
        matches({ type: "rating" }),
        ({ key, text }) => (
          <RatingQuestion text={text[campaignLanguage]} id={key} key={key} />
        ),
      ],
      [
        matches({ type: "freetext" }),
        ({ key, text }) => (
          <FreetextQuestion text={text[campaignLanguage]} id={key} key={key} />
        ),
      ],
    ])
  );

  return (
    <div>
      <Intro language={campaignLanguage}/>
      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Form noValidate onSubmit={formik.handleSubmit}>
            {map(campaign.question_groups, ({ label, questions }, index) => {
              const formFields = buildFormFields(questions);

              return (
                <QuestionGroup label={label[campaignLanguage]} key={index}>
                  {formFields}
                </QuestionGroup>
              );
            })}

            <PrivacyNotice
              id={PRIVACY_NOTICE_FIELD_ID}
              language={campaignLanguage}
            />
            {submitButton(formik)}
          </Form>
        )}
      </Formik>
    </div>
  );
}
