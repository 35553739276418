import React from "react";
import Spinner from "react-bootstrap/Spinner";

export default function LoadingSpinner() {
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{
        minHeight: "20rem",
      }}
    >
      <Spinner
        animation="grow"
        variant="dark"
        style={{
          width: "4rem",
          height: "4rem",
        }}
      />
    </div>
  );
}
